<template>
  <div id="ProductDetail">
    <v-container class="lighten-5">
      <v-container>
        <dashboard-core-app-bar />
      </v-container>
      <v-banner sticky shaped>
        <v-fab-transition>
          <v-btn color="white" absolute top right fab sticky small x-small>
            <v-col v-if="statut === 'POSTE'">
              <v-img style="margin-top: 70px" max-width="80px" :src="POSTE" />
            </v-col>
            <v-col v-if="statut === 'INVALIDE'">
              <v-img
                style="margin-top: 70px"
                max-width="80px"
                :src="INVALIDE"
              />
            </v-col>
            <v-col v-if="statut === 'PUBLIE'">
              <v-img style="margin-top: 70px" max-width="80px" :src="PUBLIÉ" />
            </v-col>
            <v-col v-if="statut === 'VALIDE'">
              <v-img style="margin-top: 70px" max-width="80px" :src="VALIDE" />
            </v-col>
          </v-btn>
        </v-fab-transition>
      </v-banner>
      <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
      <v-row style="margin-top: 50px">
        <v-col cols="12" sm="6" lg="6">
          <base-material-stats-card
            color="info"
            icon="mdi-eye-outline"
            title="Nombres de vues"
            v-model="numberOfViews"
          >
          </base-material-stats-card>
        </v-col>
      </v-row>

      <form style="margin-top: 40px">
        <v-col>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-btn @click="redirectToClient()" text class="ma-2">
                <v-text-field label="Posté par" v-model="userName">
                </v-text-field>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                class="ma-2"
                label="ID d'utilisateur "
                v-model="userId"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                class="ma-2"
                label="Statut"
                v-model="statut"
                type="text"
                outlined
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <v-autocomplete
                label="Changer le Statut de produit"
                v-model="selectedValues"
                :items="itemList"
                item-text="name"
                item-value="id"
                hide-no-data
                hide-selected
                chips
                deletable-chips
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <v-btn
                class="ma-2"
                color="#19b4bf"
                :disabled="selectedValues == null"
                @click="redirectToDialog()"
              >
                Confirmer
                <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
              </v-btn>
            </v-col>
            <div class="text-center">
              <v-dialog v-model="dialog" width="500">
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Changement du Statut
                  </v-card-title>

                  <v-card-text> Êtes vous sur de votre choix ? </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red lighten-2" text @click="dialog = false">
                      Non
                    </v-btn>
                    <v-btn
                      color="#19b4bf"
                      text
                      v-on:click.prevent="updateStatut"
                      @click="dialog = false"
                    >
                      Oui
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-row>
        </v-col>
        <v-col class="d-flex">
          <v-row>
            <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
              <v-card class="elevation-5 flex d-flex flex-column">
                <v-card-title>General</v-card-title>
                <v-card-text>
                  <v-spacer></v-spacer>
                  <v-divider></v-divider>

                  <v-menu
                    v-model="menuCalendar"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    outlined
                    readonly
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="releaseDate"
                        label="Date de création"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                  </v-menu>
                  <v-text-field
                    label="Nom"
                    v-model="product_name"
                    outlined
                    readonly
                  ></v-text-field>
                  <v-col class="d-flex">
                    <v-text-field
                      label="Marque"
                      v-model="mark"
                      outlined
                      readonly
                    ></v-text-field>
                    <v-text-field
                      class="mx-8"
                      label="Modèle"
                      v-model="model"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex">
                    <v-text-field
                      label="Catégorie"
                      v-model="categorie"
                      outlined
                      readonly
                    ></v-text-field>
                    <v-text-field
                      class="mx-8"
                      label="Sous-catégorie"
                      v-model="type"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-textarea
                    name="description"
                    label="Description"
                    v-model="description"
                    hint="Hint text"
                    outlined
                    readonly
                  ></v-textarea>
                  <v-text-field
                    label="État"
                    v-model="etat"
                    outlined
                    readonly
                  ></v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6" class="pa-3 d-flex flex-column">
              <v-card class="elevation-5 flex d-flex flex-column">
                <v-card-title>Details </v-card-title>
                <v-card-text>
                  <v-spacer></v-spacer>
                  <v-divider></v-divider>
                  <v-text-field
                    label="Prix"
                    v-model="prix"
                    outlined
                    readonly
                  ></v-text-field>
                  <v-col class="d-flex">
                    <v-text-field
                      label="Ventes"
                      v-model="sale"
                      outlined
                      readonly
                    ></v-text-field>
                    <v-text-field
                      class="mx-8"
                      label="Disponibilité"
                      v-model="availability"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex">
                    <v-text-field
                      label="Quantité totale"
                      v-model="totalQty"
                      outlined
                      readonly
                    ></v-text-field>
                    <v-text-field
                      class="mx-8"
                      label="Quantité totale Taux moyen pondéré"
                      v-model="totalQtyTmp"
                      outlined
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-text-field
                    label="Couleur"
                    v-model="couleur"
                    outlined
                    readonly
                  ></v-text-field>
                  <v-row align="center" justify="center">
                    <v-col>
                      <v-carousel
                        cycle
                        hide-delimiter-background
                        show-arrows-on-hover
                        hide-delimiters
                      >
                        <v-carousel-item
                          style="margin-left: 50px"
                          v-for="(Images, i) in Images"
                          :key="i"
                          max-width="230px"
                          :src="Images"
                          reverse-transition="fade-transition"
                          transition="fade-transition"
                        >
                        </v-carousel-item>
                      </v-carousel>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </form>
      <v-row justify="center">
        <v-dialog v-model="dialogMotifInvalidite" persistent max-width="600">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card class="mx-auto px-6 py-8">
              <v-card-title>Veuillez saisir le motif d'invalidité</v-card-title>
              <v-textarea
                label="Motif d'invalidité"
                v-model="motifInvalidite"
                :rules="rules"
              ></v-textarea>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="closeDialogMotifInvalidite()"
                >
                  Annuler
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="validateChampMotifInvalidite()"
                >
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import db from "./../../../firebaseInit";
import NodesName from "./../../utils/nodesName";
import "vue-json-pretty/lib/styles.css";
import Constants from "./../../utils/constants";
import moment from "moment";
import INVALIDE from "@/assets/iconeStatut/INVALIDE.svg";
import POSTE from "@/assets/iconeStatut/POSTE.svg";
import PUBLIÉ from "@/assets/iconeStatut/PUBLIÉ.svg";
import VALIDE from "@/assets/iconeStatut/VALIDE.svg";
import firebase from "firebase";
export default {
  name: "ProductDetail",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
  },
  data: function () {
    return {};
  },
  data() {
    return {
      INVALIDE: INVALIDE,
      POSTE: POSTE,
      PUBLIÉ: PUBLIÉ,
      VALIDE: VALIDE,
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Posted Products",
          disabled: false,
          to: "/Mercarue/Products/PostedProducts",
        },
      ],
      selectedValues: null,
      userName: null,
      userId: null,
      newStatut: null,
      dialog: null,
      product_id: null,
      type: null,
      mark: null,
      description: null,
      Images: [],
      product_name: null,
      configs: null,
      statut: null,
      categorie: null,
      model: null,
      releaseDate: null,
      numberOfViews: null,
      date: null,
      prix: null,
      sale: null,
      availability: null,
      totalQty: null,
      totalQtyTmp: null,
      couleur: null,
      etat: null,
      menuCalendar: null,
      itemList: [],
      dialogMotifInvalidite: false,
      valid: true,
      rules: [(v) => !!v || "Champ obligatoire"],
      motifInvalidite: "",
    };
  },

  beforeRouteEnter(to, from, next) {
    db.collection(NodesName.PRODUCT)
      .doc(to.params.product_id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          next((vm) => {
            vm.userId = doc.data().createdBy;
            vm.type = to.params.type;
            vm.mark = to.params.mark;
            vm.product_id = doc.id;
            vm.product_name = doc.data().name;
            vm.description = doc.data().description;
            vm.configs = doc.data().configurations;
            vm.statut = doc.data().state;
            vm.categorie = doc.data().category;
            vm.model = doc.data().model;
            vm.releaseDate = moment(
              new Date(doc.data().creationDate.seconds * 1000)
            ).format("YYYY-MM-DD");
            vm.numberOfViews = doc.data().numberOfViews.toString();
            vm.prix = doc.data().price;
            vm.sale = doc.data().sales;
            vm.availability = doc.data().availability;
            vm.totalQty = doc.data().totalQty;
            vm.totalQtyTmp = doc.data().totalQtyTmp;
            vm.couleur = doc.data().color;
            vm.etat = doc.data().productStateName;
            vm.Images = doc.data().images;
          });
        } else {
          console.log("No such document!");
        }
      });
  },
  methods: {
    redirectToDialog() {
      if (this.selectedValues == 1) {
        this.dialogMotifInvalidite = true;
      } else {
        this.dialog = true;
      }
    },
    validateChampMotifInvalidite() {
      if (this.$refs.form.validate()) {
        this.dialog = true;
      }
    },
    closeDialogMotifInvalidite() {
      this.dialogMotifInvalidite = false;
      this.$refs.form.reset();
    },
    redirectToClient() {
      this.$router.push({
        name: "UserDetail",
        params: {
          userId: this.userId,
          username: this.username,
        },
      });
    },
    //  requette pour update statut
    updateStatut() {
      if (this.selectedValues == 1) {
        db.collection(NodesName.PRODUCT)
          .doc(this.product_id)
          .update({
            admin_history_productState:
              firebase.firestore.FieldValue.arrayUnion({
                updatedBy: firebase.auth().currentUser.uid,
                updatedDate: new Date(),
                stateFrom: this.statut,
                stateTo: Constants.INVALIDE,
                motifInvalidite: this.motifInvalidite,
              }),
            state: Constants.INVALIDE,
            motifInvalidite: this.motifInvalidite,
          });
      } else if (this.selectedValues == 2) {
        db.collection(NodesName.PRODUCT)
          .doc(this.product_id)
          .update({
            admin_history_productState:
              firebase.firestore.FieldValue.arrayUnion({
                updatedBy: firebase.auth().currentUser.uid,
                updatedDate: new Date(),
                stateFrom: this.statut,
                stateTo: Constants.PUBLIE,
                motifInvalidite: null,
              }),
            state: Constants.PUBLIE,
            motifInvalidite: null,
          });
      } else if (this.selectedValues == 3) {
        db.collection(NodesName.PRODUCT)
          .doc(this.product_id)
          .update({
            admin_history_productState:
              firebase.firestore.FieldValue.arrayUnion({
                updatedBy: firebase.auth().currentUser.uid,
                updatedDate: new Date(),
                stateFrom: this.statut,
                stateTo: Constants.VALIDE,
                motifInvalidite: null,
              }),
            state: Constants.VALIDE,
            motifInvalidite: null,
          });
      }

      this.$router.push({
        name: "PostedProducts",
        params: { mark: this.mark, type: this.type },
      });
    },
    getStatusToModify(productStatus) {
      switch (productStatus) {
        case Constants.VALIDE:
          this.itemList = [
            { id: 1, name: Constants.LIBELLE_INVALIDER },
            { id: 2, name: Constants.LIBELLE_PUBLIER },
          ];
          break;
        case Constants.INVALIDE:
          this.itemList = [{ id: 3, name: Constants.LIBELLE_VALIDER }];
          break;
        case Constants.POSTE:
          this.itemList = [
            { id: 1, name: Constants.LIBELLE_INVALIDER },
            { id: 3, name: Constants.LIBELLE_VALIDER },
          ];
          break;
        case Constants.PUBLIE:
          this.itemList = [{ id: 1, name: Constants.LIBELLE_INVALIDER }];
          break;
      }
    },
  },
  mounted() {
    this.getStatusToModify(this.statut);
    db.collection(NodesName.USERS_DETAILS)
      .doc(this.userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.userName = doc.data().userName;
        }
      });
  },
};
</script>
<style>
.v-image__image--cover {
  background-size: contain !important;
}
</style>
